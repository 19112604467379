export const Exterior = [
  {
    label: "Back Doors",
    error: "Back Doors",
    name: "Exterior_BackDoors",
  },
  {
    label: "Deck, porch, patio ",
    error: "Deck, porch, patio ",
    name: "Exterior_DeckPorchPatio",
  },
  {
    label: "Doorbell ",
    error: "Doorbell ",
    name: "Exterior_Doorbell",
  },
  {
    label: "Driveway ",
    error: "Driveway ",
    name: "Exterior_Driveway",
  },
  {
    label: "Front Doors ",
    error: "Front Doors ",
    name: "Exterior_FrontDoors",
  },
  {
    label: "Garage Doors ",
    error: "Garage Doors ",
    name: "Exterior_GarageDoors",
  },
  {
    label: "Garbage receptacle ",
    error: "Garbage receptacle ",
    name: "Exterior_GarbageReceptacle",
  },
  {
    label: "House number ",
    error: "House number ",
    name: "Exterior_HouseNumber",
  },
  {
    label: "Mailbox ",
    error: "Mailbox ",
    name: "Exterior_Mailbox",
  },
  {
    label: "Outdoor lights ",
    error: "Outdoor lights ",
    name: "Exterior_OutdoorLights",
  },
  {
    label: "Paint and trim ",
    error: "Paint and trim ",
    name: "Exterior_PaintAndTrim",
  },
  {
    label: "Parking ",
    error: "Parking ",
    name: "Exterior_Parking",
  },
  {
    label: "Recycling receptacle ",
    error: "Recycling receptacle ",
    name: "Exterior_RecyclingReceptacle",
  },
  {
    label: "Sidewalks ",
    error: "Sidewalks ",
    name: "Exterior_Sidewalks",
  },
  {
    label: "Siding (brick/stone/cement) ",
    error: "Siding (brick/stone/cement) ",
    name: "Exterior_Siding_brick_stone_cement",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "Exterior_Windows",
  },
];

export const Yard = [
  {
    label: "Drainage ",
    error: "Drainage ",
    name: "Yard_Drainage",
  },
  {
    label: "Fences and gates ",
    error: "Fences and gates ",
    name: "Yard_FencesAndGates",
  },
  {
    label: "Retaining wall ",
    error: "Retaining wall ",
    name: "Yard_Retainingwall",
  },
  {
    label: "Shed ",
    error: "Shed ",
    name: "Yard_Shed",
  },
  {
    label: "Sprinklers ",
    error: "Sprinklers ",
    name: "Yard_Sprinklers",
  },
  {
    label: "Swimming pool  ",
    error: "Swimming pool  ",
    name: "Yard_SwimmingPool ",
  },
];

export const Roof = [
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "Roof_Ceiling",
  },
  {
    label: "Gutters and downspouts ",
    error: "Gutters and downspouts ",
    name: "Roof_GuttersAndDownspouts",
  },
  {
    label: "Soffits and fascia ",
    error: "Soffits and fascia ",
    name: "Roof_SoffitsAndFascia",
  },
];

export const Garage = [
  {
    label: "Doors ",
    error: "Doors ",
    name: "Garage_Doors",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "Garage_Floors",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Garage_Lights",
  },
  {
    label: "Storage ",
    error: "Storage ",
    name: "Garage_Storage",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Garage_Walls",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "Garage_Windows",
  },
];

export const Fireplace = [
  {
    label: "Blockages ",
    error: "Blockages ",
    name: "Fireplace_Blockages",
  },
  {
    label: "Carbon Monoxide Detector ",
    error: "Carbon Monoxide Detector ",
    name: "Fireplace_CarbonMonoxideDetector",
  },
  {
    label: "Mantle ",
    error: "Mantle ",
    name: "Fireplace_Mantle",
  },
  {
    label: "Soot ",
    error: "Soot ",
    name: "Fireplace_Soot",
  },
  {
    label: "Tiles ",
    error: "Tiles ",
    name: "Fireplace_Tiles",
  },
];

export const Bathrooms = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "Bathrooms_Baseboards",
  },
  {
    label: "Cabinets, shelves, & drawers ",
    error: "Cabinets, shelves, & drawers ",
    name: "Bathrooms_CabinetsShelvesDrawers",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "Bathrooms_Ceiling",
  },
  {
    label: "Counter ",
    error: "Counter ",
    name: "Bathrooms_Counter",
  },
  {
    label: "Doors ",
    error: "Doors ",
    name: "Bathrooms_Doors",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "Bathrooms_Floors",
  },
  {
    label: "GFCI outlet ",
    error: "GFCI outlet ",
    name: "Bathrooms_GFCIOutlet",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Bathrooms_Lights",
  },
  {
    label: "Mirror ",
    error: "Mirror ",
    name: "Bathrooms_Mirror",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "Bathrooms_OutletsAndSwitches",
  },
  {
    label: "Sink ",
    error: "Sink ",
    name: "Bathrooms_Sink",
  },
  {
    label: "Toilet ",
    error: "Toilet ",
    name: "Bathrooms_Toilet",
  },
  {
    label: "Towel hook ",
    error: "Towel hook ",
    name: "Bathrooms_TowelHook",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "Bathrooms_Trim",
  },
  {
    label: "Tub and shower ",
    error: "Tub and shower ",
    name: "Bathrooms_TubAndShower",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Bathrooms_Walls",
  },
  {
    label: "Window ",
    error: "Window ",
    name: "Bathrooms_Window",
  },
];

export const Bedrooms = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "Bedrooms_Baseboards",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "Bedrooms_Ceiling",
  },
  {
    label: "Closet ",
    error: "Closet ",
    name: "Bedrooms_Closet",
  },
  {
    label: "Doors ",
    error: "Doors ",
    name: "Bedrooms_Doors",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "Bedrooms_Floors",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Bedrooms_Lights",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "Bedrooms_OutletsAndSwitches",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "Bedrooms_Trim",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Bedrooms_Walls",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "Bedrooms_Windows",
  },
];

export const DiningRoom = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "DiningRoom_Baseboards",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "DiningRoom_Ceiling",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "DiningRoom_Floors",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "DiningRoom_Lights",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "DiningRoom_OutletsAndSwitches",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "DiningRoom_Trim",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "DiningRoom_Walls",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "DiningRoom_Windows",
  },
];

export const LivingRoom = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "LivingRoom_Baseboards",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "LivingRoom_Ceiling",
  },
  {
    label: "Ceiling fan ",
    error: "Ceiling fan ",
    name: "LivingRoom_CeilingFan",
  },
  {
    label: "Doors ",
    error: "Doors ",
    name: "LivingRoom_Doors",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "LivingRoom_Floors",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "LivingRoom_Lights",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "LivingRoom_OutletsAndSwitches",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "LivingRoom_Trim",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "LivingRoom_Walls",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "LivingRoom_Windows",
  },
];

export const Kitchen = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "Kitchen_Baseboards",
  },
  {
    label: "Cabinets and drawers ",
    error: "Cabinets and drawers ",
    name: "Kitchen_CabinetsAndDrawers",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "Kitchen_Ceiling",
  },
  {
    label: "Counter ",
    error: "Counter ",
    name: "Kitchen_Counter",
  },
  {
    label: "Dishwasher ",
    error: "Dishwasher ",
    name: "Kitchen_Dishwasher",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "Kitchen_Floors",
  },
  {
    label: "Garbage Disposal  ",
    error: "Garbage Disposal  ",
    name: "Kitchen_GarbageDisposal ",
  },
  {
    label: "GFCI outlet ",
    error: "GFCI outlet ",
    name: "Kitchen_GFCIOutlet",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Kitchen_Lights",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "Kitchen_OutletsAndSwitches",
  },
  {
    label: "Oven ",
    error: "Oven ",
    name: "Kitchen_Oven",
  },
  {
    label: "Refrigerator ",
    error: "Refrigerator ",
    name: "Kitchen_Refrigerator",
  },
  {
    label: "Sink ",
    error: "Sink ",
    name: "Kitchen_Sink",
  },
  {
    label: "Smoke Detectors ",
    error: "Smoke Detectors ",
    name: "Kitchen_SmokeDetectors",
  },
  {
    label: "Stove ",
    error: "Stove ",
    name: "Kitchen_Stove",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "Kitchen_Trim",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Kitchen_Walls",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "Kitchen_Windows",
  },
];

export const Den = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "Den_Baseboards",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "Den_Ceiling",
  },
  {
    label: "Ceiling fan ",
    error: "Ceiling fan ",
    name: "Den_CeilingFan",
  },
  {
    label: "Doors ",
    error: "Doors ",
    name: "Den_Doors",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "Den_Floors",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Den_Lights",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "Den_OutletsAndSwitches",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "Den_Trim",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Den_Walls",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "Den_Windows",
  },
];

export const Attic = [
  {
    label: "Insulation ",
    error: "Insulation ",
    name: "Attic_Insulation",
  },
  {
    label: "Ladder ",
    error: "Ladder ",
    name: "Attic_Ladder",
  },
  {
    label: "Ventilation ",
    error: "Ventilation ",
    name: "Attic_Ventilation",
  },
];

export const Hallways = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "Hallways_Baseboards",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "Hallways_Ceiling",
  },
  {
    label: "Closets ",
    error: "Closets ",
    name: "Hallways_Closets",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "Hallways_Floors",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Hallways_Lights",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "Hallways_OutletsAndSwitches",
  },
  {
    label: "Skylights ",
    error: "Skylights ",
    name: "Hallways_Skylights",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "Hallways_Trim",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Hallways_Walls",
  },
];

export const Basement = [
  {
    label: "Baseboards ",
    error: "Baseboards ",
    name: "Basement_Baseboards",
  },
  {
    label: "Ceiling ",
    error: "Ceiling ",
    name: "Basement_Ceiling",
  },
  {
    label: "Doors ",
    error: "Doors ",
    name: "Basement_Doors",
  },
  {
    label: "Floors ",
    error: "Floors ",
    name: "Basement_Floors",
  },
  {
    label: "Foundation ",
    error: "Foundation ",
    name: "Basement_Foundation",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Basement_Lights",
  },
  {
    label: "Outlets and switches ",
    error: "Outlets and switches ",
    name: "Basement_OutletsandSwitches",
  },
  {
    label: "Stairs ",
    error: "Stairs ",
    name: "Basement_Stairs",
  },
  {
    label: "Storage ",
    error: "Storage ",
    name: "Basement_Storage",
  },
  {
    label: "Sump pump ",
    error: "Sump pump ",
    name: "Basement_SumpPump",
  },
  {
    label: "Trim ",
    error: "Trim ",
    name: "Basement_Trim",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Basement_Walls",
  },
  {
    label: "Washer and dryer ",
    error: "Washer and dryer ",
    name: "Basement_WasherAndDryer",
  },
  {
    label: "Windows ",
    error: "Windows ",
    name: "Basement_Windows",
  },
];

export const Stairs = [
  {
    label: "Floors ",
    error: "Floors ",
    name: "Stairs_Floors",
  },
  {
    label: "Landing ",
    error: "Landing ",
    name: "Stairs_Landing",
  },
  {
    label: "Lights ",
    error: "Lights ",
    name: "Stairs_Lights",
  },
  {
    label: "Railing ",
    error: "Railing ",
    name: "Stairs_Railing",
  },
  {
    label: "Treads ",
    error: "Treads ",
    name: "Stairs_Treads",
  },
  {
    label: "Walls ",
    error: "Walls ",
    name: "Stairs_Walls",
  },
];

export const Utilities = [
  {
    label: "Air conditioning ",
    error: "Air conditioning ",
    name: "Utilities_AirConditioning",
  },
  {
    label: "Circuit breakers ",
    error: "Circuit breakers ",
    name: "Utilities_CircuitBDrainagereakers",
  },
  {
    label: "Drainage ",
    error: "Drainage ",
    name: "Utilities_Drainage",
  },
  {
    label: "Furnace ",
    error: "Furnace ",
    name: "Utilities_Furnace",
  },
  {
    label: "Hot water heater ",
    error: "Hot water heater ",
    name: "Utilities_HotWaterHeater",
  },
  {
    label: "Main electrical panel ",
    error: "Main electrical panel ",
    name: "Utilities_MainElectricalPanel",
  },
  {
    label: "Water pressure ",
    error: "Water pressure ",
    name: "Utilities_WaterPressure",
  },
];

export const Other = [
  {
    label: "Asbestos ",
    error: "Asbestos ",
    name: "Other_Asbestos",
  },
  {
    label: "Odor ",
    error: "Odor ",
    name: "Other_Odor",
  },
  {
    label: "Wood Eating insects ",
    error: "Wood_eating insects ",
    name: "Other_WoodEatingInsects",
  },
];
